@import "../node_modules/boosted/scss/functions";
@import "../node_modules/boosted/scss/variables";
@import "../node_modules/boosted/scss/variables-dark";
@import "../node_modules/boosted/scss/maps";
@import "../node_modules/boosted/scss/mixins";
@import "../node_modules/boosted/scss/root";
@import "../node_modules/boosted/scss/utilities";

$primary: #FF7900;
$highlightWhite: $gray-300;
$highlightBlack: $gray-900;

/** Corporate colour scheme **/
$black: #000000;
$darkgrey: #595959;
$white: #ffffff;
$midgrey: #8f8f8f;
$lightgrey: #d6d6d6;
$orange: #FF7900;

$supportingBlue: #4BB4E6;
$supportingGreen: #50BE87;
$supportingPink: #FFB4E6;
$supportingPurple: #A885D8;
$supportingYellow: #FFD200;

/** Corporate accessible darker colour scheme for white background **/
$darkerRed: #CD3C14;
$darkerOrange: #F16E00;

$supportingDarkerBlue: #085EBD;
$supportingDarkerGreen: #0A6E31;
$supportingDarkerPink: #FF8AD4;
$supportingDarkerPurple: #492191;
$supportingDarkerYellow: #FFB400;



/** SIGNAL URGENCIES **/
$information: #8f8f8f;
$planning: #9fd8f3;
$low: #4bb4e6;
$medium: $warning;
$high: #ff7900;
$critical: #e75b60;
$danger: red;

/* CUSTOM BLACK THEME */
.bg-black {

    color: $white !important;

    .btn.btn-primary:hover {
        background-color: $white;
        color: $black;
    }

    .btn.btn-secondary {
        background-color: $black;
        color: $white;
        border-color: $white;
        &:hover {
            background-color: $white;
            color: $black;
            border-color: $black;
        }
    }

    .btn.dropdown-toggle:disabled {
        background-color: #333 !important;
        color: #ccc !important;
    }

    .btn:disabled {
        background-color: #333 !important;
        color: #ccc !important;
    }

    .btn.search.selected {
        color: $black;
        background-color: $white;
    }

    .btn-outline-secondary {
        border-color: $white;
        color: $white;
        &:hover:not(.active) {
            background-color: $white;
            color: black;
        }
    }

    .form-select,
    .form-control:not(.form-control-color) {
        background-color: $black;
        border-color: #ccc;
        color: $white;

        &:disabled {
            background-color: #333;
            color: #ccc;
        }

        &:focus {
            border-color: $white !important;
            background-color: $black !important;
            color: $white !important;
        }
    }

    .faketablerow{
        color: $white;
        &:hover {
            background: #333;
            color: $primary;
            cursor: pointer;
        }
    }

    .faketableheader {
        color: $white;
    }

    .btn.dropdown-toggle, .dropdown-menu {
        background-color: $black;
        color: $white;
    }

    .dropdown-menu {
        background-color: $black;

        .dropdown-item:hover {
            background-color:#666666;
            color: $white;
        }
    }
    
    .form-switch .form-check-input {
        border-color: $black !important;

        &:checked {
            border-color: $primary !important;
        }
    }

    .form-check-input:disabled:checked, .form-check-input:disabled:indeterminate {
        background-color: invert($color: #333) !important;
      }

    .widget-section {
        .btn:hover {
            color: $black;
            background-color: $white;
        }

        .wrapper {
            background-color: $gray-900;
        }
    }

    .card {
        background-color: $black;

        &.highlight:hover {
            background-color: $highlightBlack !important;
            border-color: $gray-100;
        }
    }


    .highlight:hover {
        background-color: $highlightBlack !important;
        border-color: $gray-100;
    }

    .nav-tabs {
        border-color: #cccccc;
        color: $white;

        .nav-link {

            &.active {
                border-color: #cccccc;
                background-color: $black;
                color: $white !important;
            }

            &:hover {
                background-color: $white;
                color: $black !important;
            }
        }
    }

    .accordion-item {
        &:hover {
            background-color: $gray-800 !important;
        }
    }

    .graph {
        .btn {
          border-color: $black;
          background-color: $gray-900 !important;
          color: $gray-700;
          
          &:hover {
            color: $primary;
          }
        
          &.active {
            color: $white !important;
          }

        }
    }

    .btn-close {
        color: $white;
        &:hover {
            color: $white;
            border-color: $black;
        }
    }

    .action-icon {
        stroke: $orange;
        color: $orange;
        &:hover {
            stroke: $white;
            color: $white;
        }
        &.black-or-white {
            stroke: $white;
            color: $white;
            &:hover {
                stroke: $orange;
                color: $orange;
            }
        }
    }
}

/* CUSTOM DEEP BLACK THEME */
::ng-deep .bg-black {

    .input-info-message {
        color: $supportingBlue;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        color: $white;
        background-color: $black;

        &:hover {
            color: $black;
            background-color: $white;
        }
    }

    .carousel-indicators {
        background-color: $white;
    }

    .pagination .page-link {
        background-color: $highlightBlack;
        border: solid 2px $gray-400;
    }

    .multiselect-dropdown {

        .dropdown-btn {
            border-color: #cccccc !important;

            &:focus {
                border-color: $white !important;
            }
        }

        .dropdown-list {
            background-color: $black;

            .multiselect-item-checkbox {
                input+div {
                    color: $white !important;
                }

                &:hover {
                    input+div {
                        color: $black !important;
                    }
                }
            }
        }

        span {
            background-color: transparent;
            color: $white !important;
            font-weight: bold;
        }

    }

    caption {
        color: $white;
    }

    .form-select {
        background-image: url('assets/images/icons/caret-down-fill.svg') !important;
    }

    .tooltip-inner {
        border-color: $white;
        background-color: $black;
        color: $white;
    }

    .sticker.secondary {
        --bs-sticker-background-color: white;
        color: black;
    }
}

/* CUSTOM WHITE THEME */
.bg-white {

    color: $black !important;

    .input-info-message {
        color: $supportingDarkerBlue;
    }

    .btn.search.selected {
        color: $white;
        background-color: $black;
    }

    .sticker {
        color:$black;
    }

    .sticker.secondary {
        --bs-sticker-background-color: black;
        color: white;
    }

    .faketableheader {
        color: $black;
    }

    .faketablerow:hover {
        background: $highlightWhite;
        color: $primary;
        cursor: pointer;
    }

    .nav-tabs {
        border-color: #333;

        .nav-link {

            &.active {
                border-color: #333;
            }
        }
    }

    .accordion-item {
  
        &:hover {
          background-color: $gray-200 !important;
        }
      }

    .widget-section {
        color: $black;

        .wrapper {
            background-color: $gray-300;
        }
    }

    .card {
        background-color: $white;
        border-color: $gray-500;
        &.highlight:hover {
            background-color: $gray-400 !important;
            border-color: $gray-900;
        }
    }



    .highlight:hover {
        background-color: $gray-400 !important;
        border-color: $gray-900;
    }

    h3 {
        color: $black;
    }

    .multiselect-dropdown .dropdown-btn:focus {
        border-color: $black !important;
    }

    .graph {
        .btn {
          border-color: $white;
          background-color: $gray-200 !important;
          color: $gray-600;
        
          &:hover {
            color: $primary;
          }

          &.active {
            color: $black !important;
          }

        }
    }

    .action-icon {
        stroke: $orange;
        color: $orange;
        &:hover {
            stroke: $black;
            color: $black;
        }
        &.black-or-white {
            stroke: $black;
            color: $black;
            &:hover {
                stroke: $orange;
                color: $orange;
            }
        }
    }
}


/* CUSTOM CHILD COMPONENT (NG-DEEP) */
::ng-deep {

    .tooltip-inner {
        border-color: $black;
        background-color: $white;
        color: $black;
    }

    .multiselect-dropdown {

        .dropdown-btn {
            border: 2px #cccc solid !important;
            border-width: 2px !important;
            border-style: solid !important;
            border-radius: 0 !important;
        }

        .dropdown-list {

            .item1>li {
                padding-top: 10px !important;
                padding-bottom: 15px !important;
            }

            .multiselect-item-checkbox {

                font-weight: bold;

                input+div {

                    &::before {
                        border-color: $primary !important;
                        background-color: transparent !important;
                    }
                }

                div::after {
                    border-color: $primary !important;
                }
            }
        }

        span {
            background-color: transparent;
            font-weight: bold;
            .selected-item {
                color: $white;
            }
        }
    }
}

// To keep label design on div tags
.custom-label {
    margin-bottom: 0.625rem;
    font-weight: bold;
}

// Style input errors in forms
.input-error-message {
    color: rgb(205, 60, 50);
    font-weight: bold;
    margin-left: 1rem;
}

// Style input info in forms
.input-info-message {
    font-weight: bold;
    margin-left: 1rem;
}

/* MULTI-SELECT-DROPDOWN  */
::ng-deep ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .selected-item-container {

    .selected-item {
        max-width: none !important;
        margin-bottom: 3px;
      }
  } 