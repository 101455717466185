@import 'stylevars.scss';

/**
    1.  Colours
    2.  Module themes
    3.  Layout
    4.  Utils
    5.  Links
    6.  Signals
**/

/**     1. Colours      **/
/** Core corporate colours **/
.black {
  color: $orange;
}

.darkgrey {
  color: $darkgrey;
}

.white {
  color: $white;
}

.midgrey {
  color: $midgrey;
}

.lightgrey {
  color: $lightgrey;
}

.orange {
  color: $orange;
}

.blue {
  color: $blue;
}

.green {
  color: $green;
}

.pink {
  color: $pink;
}

.purple {
  color: $purple;
}

.yellow {
  color: $yellow;
}


/**     2. Module Themes    **/
.support {
  background-color: $yellow;
}

.activity {
  background-color: $blue;
}

.assets {
  background-color: $green;
}

.reports {
  background-color: $purple;
}

.updates {
  background-color: $pink;
}


/**     3. Layout       **/
/* Force full width when resizing for responsive */
html {
  width: 100vw;
}

/* Hide log ticket button to allow menu to fit */
@media (max-width: 1024px) {
  .logTicket {
    visibility: hidden;
  }
}

/** Flex justify **/
.justify-content-center {
  display: flex !important;
  justify-content: center !important;
}

.justify-content-right {
  display: flex !important;
  justify-content: flex-end !important;
}

/** Appropriate mouseover **/
.pointer {
  cursor: pointer;
}

/** Format the first box of all 'At A Glance' summary boxes **/
.status-table .item.first-col-status {
  padding: 15px;
  min-height: 80px;
  color: #fff;
  text-align: left;
  background-color: #4bb4e6;
  background-image: url(/assets/img/icon-jauge-transparent.svg);
  background-repeat: no-repeat;
  background-position: right 10px bottom;
  background-size: 64px;
  margin: 0;
}

.breadcrumb {
  margin-bottom: 0 !important;
}

/* fake table styles for bootstrap grid, more responsive */
.faketableheader {
  padding: 0.875rem 0.625rem calc(0.875rem + 1px);
  line-height: 1.25;
  vertical-align: top;
  font-weight: bold;
  text-align: center;
}

.faketableheaderrow {
  border-bottom: 0.0625rem solid #ccc;
}

.faketablerow {
  border-bottom: 0.0625rem solid #ccc;
}

.faketablecell {
  padding: 0.875rem 0.625rem calc(0.875rem + 1px);
  line-height: 1.25;
  vertical-align: top;
  text-align: center;
}

/**     4. Utils       **/
.click {
  cursor: pointer;
  text-decoration: none;
}

.click:hover {
  text-decoration: none;
  color: $primary;
}

.noclick {
  cursor: unset;
}

.noclick.hover {
  cursor: unset;
}


.preserve-whitespace {
  white-space: pre-wrap;
}

.centerme {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-danger {
  color: $danger;
}

/**     5. Links      **/
*[ng-reflect-router-link]:focus {
  outline: none;
}

input,
select {
  cursor: pointer;

  &:disabled { 
    cursor: default;
  }
}

.btn {

  &:focus,
  &.focus {
    outline: none !important;
  }
}


.boxfiller {
  background-color: $lightgrey;
}

.lastLogin {
  background-color: black;
  color: white;
}

.nav-item:hover {
  cursor: pointer;
}

/**     6. Signals      **/
/* urgency icons for lists */
.signalicon-left {
  background-position: 0% 110% !important;
}

.signalicon-headingleft {
  background-position: 0% 75% !important;
  background-size: 100px !important;
  padding-left: 100px !important;
}


.dark-mode {
  $dark-color: #242424;

  .bg-body-tertiary {
      background-color: $dark-color !important;
      color: #E0E0E0 !important;
  }
}




/*
.bg-black {
  .information {
    background-color: $pink !important;
  }
  
  .vulnnone {
    background-color: $pink !important;
  }
  
  .low {
    background-color: $pink !important;
  }
  
  .medium {
    background-color: $pink !important;
  }
  
  .high {
    background-color: $pink !important;
  }
  
  .critical {
    background-color: $critical !important;
  }
}

.bg-white {
  .information {
    background-color: $pink !important;
  }
  
  .vulnnone {
    background-color: $pink !important;
  }
  
  .low {
    background-color: $pink !important;
  }
  
  .medium {
    background-color: $pink !important;
  }
  
  .high {
    background-color: $pink !important;
  }
  
  .critical { 
    background-color: $pink !important;
  }
}
*/


/**        7. Vulnerabilities       **/
/* severity icons */
.information {
  background-color: $information !important;
}

.vulnnone {
  background-color: $information !important;
}

.very-low {
  background-color: $information !important;
}

.low {
  background-color: $low !important;
}

.medium {
  background-color: $medium !important;
}

.high {
  background-color: $high !important;
}

.critical {
  background-color: $critical !important;
}

/* General */

.row {
  margin: 0;
  padding: 0;
}

.nav-item:not(.static).dropdown:hover{
  ul {
    display: block;
  }
}

.dropdown .dropdown-item.active {
  color: $primary;
}

.page .content .card {
  margin-bottom: 2rem;
}

.form-check.form-switch {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }
} 

.form-check-input[type="checkbox"] {
  background-color: transparent;
}

/* HighCharts */
.selected {
  .highcharts-point {
    opacity: 0.5;
  }

  .highcharts-point.highcharts-point-select {
    opacity: 1;
  }
}

.highcharts-tooltip table th {
  padding-bottom: 0.2em;
  border-bottom: 1px solid silver;
}

.graph {
  .btn {
    border-radius: 15px;  
  }
}

.modal-xl {
  --bs-modal-width: 800px !important;
  width: 800px !important;
}

.modal-xxl {
  --bs-modal-width: 1200px !important;
  width: 1200px !important;
}

/* home page */

.widget-section {
  
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;

  &.half-width {
    padding-left: 15%;
    padding-right: 15%;
  }

  @media(max-width: 1200px) {
    padding: 10%;
  }

  .wrapper {
    padding: 1rem;
    border-radius: 0.5rem;
  }

  .title {
    // removed underline for accessibility purposes
    // text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  .btn {
    border-radius: 0.5rem;
  }

  .count {
    color: $primary;
  }

  .cases {
    margin-top: 1rem;

    .title {
      height: 5rem;

      .left {
        display: flex;
        float: left;
        width: 80%;
      }

      .right {
        float: right;
      }
    }

    .faketableheaderrow {
      display: flex;
    }

    .main-table {
      max-height: 18rem;
      overflow-x: hidden;
      overflow-y: scroll;
      
      .faketablerow {
        align-items: flex-start;
        display: flex;
        max-height: 3rem;
        font-size: 16px;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;

        .faketablecell {
          text-align: start;
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;
          padding-left: 1rem;
        }
      }

      a {
        text-align: start;
      }
    }

    .footer-table {
      height: 2rem;
    }
  }
}

.cdk-drag-preview {
  z-index: 9000 !important;
}

.sticker {
  color: $white;
  &.sticker-xs {
    --bs-sticker-size: 1.5rem !important;
    --bs-sticker-content-max-width: 1rem !important;
  }
  .sticker-fs-xs {
    font-size: 0.7rem;
    line-height: 0.75rem; // stylelint-disable-line function-disallowed-list
    letter-spacing: -.05rem;
  }
  &.sticker-fs-l {
    --bs-sticker-size: 2.5rem !important;
    --bs-sticker-content-max-width: 1rem !important;
    letter-spacing: -.03125rem;
  }
}

.tag {
  align-items: center;
  justify-content: center;

  &.no-border {
    border: none !important;
  }

  &.tag-info {
    background-color: $blue !important;
  }
  &.tag-success {
    background-color: $green !important;
  }
  &.tag-warning {
    background-color: $yellow !important;
  }
  &.tag-danger {
    background-color: $red !important;
  }
  &.tag-primary {
    background-color: $orange !important;
  }
  &.tag-secondary {
    background-color: $black !important;
  }
}

fa-icon.disabled {
  color: #ccc !important;
  cursor: default;
}

.error-message {
  font-size: 16px;
  font-weight: bold;
  color: $danger;
    &.light-theme {
      color: $darkerRed;
    }
}

.info-icon {
  color: $blue;
}

// ? Increase z-index because sticky elements can pass over dropdowns
.dropdown-menu {
  z-index: 1999 !important;
}
